var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "21",
        height: "20",
        viewBox: "0 0 21 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M20.5392 8.03977C19.3867 6.068 18.2576 4.09622 17.0857 2.14358C16.3679 0.987315 15.3241 0.363237 13.935 0.363237C11.6534 0.340265 9.34849 0.363237 7.04749 0.363237C6.69827 0.363237 6.32964 0.405353 6.0037 0.535529C5.11124 0.834167 4.37399 1.35104 3.91612 2.16655C2.78696 4.09622 1.6578 6.04502 0.528645 7.97469C0.225984 8.48773 0.0513724 9.02375 0.0086895 9.60571C-0.0339934 10.3332 0.074654 10.9994 0.466561 11.6196C1.619 13.5722 2.77144 15.521 3.9006 17.4737C4.20326 18.0327 4.66113 18.4615 5.20437 18.7601C5.72432 19.0588 6.29084 19.2961 6.90004 19.2961H13.9388C14.2648 19.2961 14.5907 19.254 14.8934 19.1468C15.8053 18.8482 16.5658 18.3543 17.0663 17.4967C18.2188 15.544 19.3479 13.5952 20.5004 11.6426C20.803 11.1066 20.9776 10.5476 21.0009 9.95029C20.997 9.24199 20.8884 8.61791 20.5392 8.03977Z",
          fill: _vm.isActive ? "#343A60" : "#717DC6",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.8859 9.03517L8.83271 16.7385C8.79002 16.7921 8.72794 16.8227 8.65809 16.8227C8.62317 16.8227 8.58825 16.8151 8.55721 16.7998C8.4602 16.75 8.41364 16.6428 8.44468 16.5432L10.0744 11.2252H6.83825C6.75676 11.2252 6.67916 11.1792 6.64036 11.1065C6.60543 11.0376 6.61319 10.9533 6.65976 10.8883L12.5306 2.93606C12.5888 2.85566 12.6974 2.82503 12.7945 2.86331C12.8876 2.9016 12.9458 2.99732 12.9303 3.09686L12.1542 8.68676H14.7113C14.7967 8.68676 14.8743 8.7327 14.9092 8.80928C14.9441 8.88202 14.9364 8.97008 14.8859 9.03517Z",
          fill: _vm.isActive ? "#717DC6" : "#FDFDFD",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }